body {
  margin: 0;
  font-family: 'prompt';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

*,
::before,
::after {
  box-sizing: border-box;
}

::-webkit-scrollbar {
  width: 7px;
  height: 7px;
}
::-webkit-scrollbar-button {
  background: transparent;
}
::-webkit-scrollbar-track-piece {
  background: transparent;
}
::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.25);
  border-radius: 12px;
}

/**
Use a more readable tab size (opinionated).
*/

html {
  -moz-tab-size: 4;
  tab-size: 4;
}

input::-ms-reveal,
input::-ms-clear {
  display: none;
}

@keyframes fadeOpacity {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
